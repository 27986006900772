<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('purchasing/add')"
        />
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Purchasing Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="code"
        header="S.N"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="Search By S.N"
            filterMatchMode="contains"
          />
        </template>
        <template #body="slotProps">
          {{ slotProps.data.code }}
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="date"
        header="Date"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="total"
        header="Total"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['total']"
            class="p-column-filter"
            placeholder="Search By Gesamt"
            filterMatchMode="contains"
          />
        </template>
        <template #body="slotProps"> {{ slotProps.data.total }}€ </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <!-- <Button
            type="button"
            @click="$router.push('admins/roles/' + slotProps.data.id)"
            icon="pi pi-key"
            v-if="slotProps.data.id != 1"
            class="p-button-info ml-2  p-button-rounded"
          ></Button> -->
          <Button
            type="button"
            @click="$router.push('purchasing/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http.get(`purchasing`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: 'Are you sure you want to delete records?',
        header: 'Yes Delete Record',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`purchasing/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'Done Successfully',
                detail: 'Deleted successfully',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
